<template>
  <v-dialog
    v-model="dialogDevolucao"
    @click:outside="$emit('update:dialogDevolucao', false)"
    @keydown.esc="$emit('update:dialogDevolucao', false)"
    width="1200px"
    scrollable
  >
    <v-card height="70vh" :loading="loading">
      <v-card-title class="tocs_gray_1">
        {{ $tc("global.devolucao") + " de " }}
        {{
          tipo_devolucao == "vendas"
            ? $tc("global.venda")
            : $tc("global.compra")
        }}
        {{ " - " + devolucao.numero }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogDevolucao', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="pa-6">
        <div class="d-flex justify-space-between">
          <span class="text-subtitle-1">
            {{ $tc("global.cliente") + ": " + devolucao.cliente_nome }}
          </span>
          <span class="text-subtitle-1">
            {{ $tc("global.deposito") + ": " + devolucao.deposito }}
          </span>
          <span class="text-subtitle-1">
            {{ $tc("global.valor") + ": " + devolucao.moeda_sigla }}
            <span v-if="devolucao.moeda_sigla === 'G$'">
              {{ devolucao.valor | guarani }}
            </span>
            <span v-else>
              {{ devolucao.valor | currency }}
            </span>
          </span>
          <span class="text-subtitle-1">
            {{ $tc("global.desconto") + ": " + devolucao.moeda_sigla }}
            <span v-if="devolucao.moeda_sigla === 'G$'">
              {{ devolucao.desconto | guarani }}
            </span>
            <span v-else>
              {{ devolucao.desconto | currency }}
            </span>
          </span>
          <span class="text-subtitle-1">
            {{ $tc("global.total") + ": " + devolucao.moeda_sigla }}
            <span v-if="devolucao.moeda_sigla === 'G$'">
              {{ devolucao.total | guarani }}
            </span>
            <span v-else>
              {{ devolucao.total | currency }}
            </span>
          </span>
        </div>
        <v-row class="mt-4">
          <v-col :cols="gerar_nota_credito ? 6 : 12">
            <v-combobox
              v-model="selectedCaixa"
              :items="caixas"
              :label="$tc('global.caixa')"
              item-text="descricao"
              item-value="id"
              hide-details
              dense
              flat
              outlined
              required
            >
            </v-combobox>
          </v-col>
          <v-col v-if="gerar_nota_credito" cols="6">
            <v-select
              v-model="selectedTimbrado"
              :items="timbrados"
              :loading="loadingTimbrado"
              item-text="timbrado"
              item-value="timbrado_id"
              hide-details
              outlined
              dense
              :required="gerar_nota_credito ? true : false"
              :label="$tc('global.timbrado')"
            >
              <template v-slot:selection="{ item }">
                <span>{{
                  `${item.descricao ? item.descricao : ""} ${item.timbrado} `
                }}</span>
              </template>
              <template v-slot:item="{ item }">
                <span>{{
                  `${item.descricao ? item.descricao : ""} ${item.timbrado} `
                }}</span>
              </template></v-select
            >
          </v-col>
        </v-row>
        <v-data-table
          v-if="!loading"
          :headers="headers"
          show-select
          :items="devolucao.venda_items"
          :items-per-page="-1"
          hide-default-footer
          checkbox-color="primary"
          class="data-tables pt-8"
          @item-selected="itemSelecionado"
          @toggle-select-all="allItemsSelecionado"
        >
          <template v-slot:item.preco="{ item }">
            {{ devolucao.moeda_sigla }}
            <span v-if="devolucao.moeda_sigla === 'G$'">
              {{ item.preco | guarani }}
            </span>
            <span v-else>
              {{ item.preco | currency }}
            </span>
          </template>
          <template v-slot:item.total="{ item }">
            {{ devolucao.moeda_sigla }}
            <span v-if="devolucao.moeda_sigla === 'G$'">
              {{ item.total | guarani }}
            </span>
            <span v-else>
              {{ item.total | currency }}
            </span>
          </template>
          <template v-slot:item.devolucao="{ item, index }">
            <div class="d-flex align-center pa-2" :key="i">
              <v-form @submit.prevent ref="form" v-model="validForm">
                <v-text-field
                  v-model="item.devolucao"
                  dense
                  hide-details
                  filled
                  :disabled="item.isSelected ? false : true"
                  :error="
                    Number(item.devolucao) >
                    Number(item.qtde) - Number(item.qtde_devolucao)
                      ? true
                      : false
                  "
                  @blur="changeQnt(item.devolucao, index)"
                  class="input-center"
                ></v-text-field>
              </v-form>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <div class="pr-4 py-4 text-right">
        <div>
          <span class="tocs_gray_3--text">Total {{ " " + $tc("global.devolucao") }}: </span>
          {{ devolucao.moeda_sigla }}
          <span v-if="devolucao.moeda_sigla === 'G$'">
            {{ total_devolucao | guarani }}
          </span>
          <span v-else>
            {{ total_devolucao | currency }}
          </span>
        </div>
      </div>
      <v-row v-if="itemSelecionados > 0" class="mx-4">
        <v-col cols="4">{{ $tc("global.selecioneFormaDevolucao") }}:</v-col>
        <v-col cols="8" class="align-start">        
          <v-radio-group v-model="forma_devolucao" hide-details row class="pa-0 ma-0">
            <v-radio
              label="Dinheiro"
              value="dinheiro"
            ></v-radio>
            <v-radio
              label="Amortizar"
              value="amortizar"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row v-if="forma_devolucao == 'dinheiro' && itemSelecionados > 0" class="mx-4">
        <v-col cols="4"></v-col>
        <v-col cols="6" class="body-2 tocs_gray_3--text">
          {{ $tc("global.devolucaoDinheiro") }}
        </v-col>
      </v-row>
      <v-row v-if="forma_devolucao == 'amortizar' && itemSelecionados > 0" class="mx-4">
        <v-col cols="4"></v-col>
        <v-col cols="6" class="body-2 tocs_gray_3--text">{{ $tc("global.devolucaoAmortizar") }}</v-col>
      </v-row>
      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-card
          flat
          class="font-weight-bold d-flex pa-0 py-1 align-center pr-3"
        >
          {{ $tc("global.gerarNotaCredito") }}
          <v-switch
            class="ma-0 ml-3 pa-0"
            hide-details
            :false-value="false"
            :true-value="true"
            v-model="gerar_nota_credito"
          ></v-switch>
        </v-card>
        <v-btn
          small
          class="button_2 white--text"
          :disabled="validaDevolucao"
          @click="enviarDevolucao"
        >
          {{ $tc("global.devolver") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { fetchVendaDevolucao } from "@/api/vendas/vendas.js";
import { devolverCompra, devolverVenda } from "@/api/devolucoes/devolucoes.js";
import { fetchUserTimbrados } from "@/api/usuarios/users_timbrado.js";
import { fetchCaixasFaturamento } from "@/api/caixas/caixas.js";
import { dataImpressaoFatura, fetchFatura } from "@/api/faturas/faturas.js";

const importFatura = () =>
  import(
    /* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_fatura_simples.js"
  );

  const importNotaCreditoAutoImpressor = () =>
  import(
    /* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_nota_credito_auto_impressor.js"
  );
  
export default {
  name: "DialogDevolucao",

  props: {
    dialogDevolucao: {
      type: Boolean,
      default: false,
    },
    item_id: {
      type: Number,
      default: null,
    },
    tipo_devolucao: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      devolucao: {},
      validForm: true,
      formRules: [inputRequired],
      itemSelecionados: 0,
      i: 0,
      selectedCaixa: null,
      caixas: [],
      total_devolucao: 0,
      total_custo: 0,
      gerar_nota_credito: false,
      selectedTimbrado: null,
      timbrados: [],
      loadingTimbrado: false,
      forma_devolucao: 'dinheiro',
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$tc("global.descricao"),
          width: "400px",
          value: "descricao",
        },
        {
          text: this.$tc("global.qtd"),
          value: "qtde",
        },
        {
          text: this.$tc("global.qtdDevolvida"),
          value: "qtde_devolucao",
        },
        {
          text: this.$tc("global.preco"),
          align: "right",
          value: "preco",
        },
        {
          text: this.$tc("global.total"),
          align: "right",
          value: "total",
        },
        {
          text: this.$tc("global.devolucao"),
          width: "150px",
          align: "center",
          value: "devolucao",
        },
      ];
    },

    validaDevolucao() {
      let result = false;

      if (this.itemSelecionados <= 0) {
        result = true;
      }

      if (!this.selectedCaixa) {
        result = true;
      }

      if (this.gerar_nota_credito && !this.selectedTimbrado) {
        result = true;
      }

      return result;
    },
  },

  watch: {
    gerar_nota_credito() {
      if (this.gerar_nota_credito) {
        this.getTimbrados();
      }
    },
  },

  methods: {
    async isAutoImpressor(fatura_id){
      const fatura = await fetchFatura(fatura_id);
      return fatura.timbrado_auto_impressor; 
    },

    async generateFatura(fatura_id, print, download, unica, tipo) {
      this.loading = true;
      if (print) {
        dataImpressaoFatura(fatura_id)
          .then(() => {})
          .catch(() => {});
      }
      try {
        const isAutoImpressor = await this.isAutoImpressor(fatura_id);
        if(isAutoImpressor){
          importNotaCreditoAutoImpressor()
          .then((module) => {
            let generate = module.default;
            generate(fatura_id, print, download, unica, tipo).then(
              (response) => {
                this.pdfData = response;
                this.pdfDialog = true;
              }
            );
          })
          .finally(() => {
            this.loading = false;
          });
        }else{
          importFatura()
          .then((module) => {
            let generate = module.default;
            generate(fatura_id, print, download, unica, tipo).then(
              (response) => {
                this.pdfData = response;
                this.pdfDialog = true;
              }
            );
          })
          .finally(() => {
            this.loading = false;
          });
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },

    getTimbrados() {
      this.loadingTimbrado = true;

      return fetchUserTimbrados("?tipo=2")
        .then((response) => {
          this.timbrados = response;
          this.loadingTimbrado = false;
        })
        .catch((error) => {
          this.loadingTimbrado = false;
        });
    },
    totalDevolucao() {
      this.total_devolucao = 0;
      if (this.devolucao && this.devolucao.venda_items) {
        this.devolucao.venda_items.forEach((item) => {
          this.total_devolucao += Number(item.devolucao) * Number(item.preco);
          this.total_custo += Number(item.cif_atual) * Number(item.qtde);
        });
      }
    },
    enviarDevolucao() {
      if (this.tipo_devolucao === "compras") {
        this.devolucaoCompra();
      }
      if (this.tipo_devolucao === "vendas") {
        this.devolucaoVenda();
      }
    },

    devolucaoVenda() {
      let existe_devolucao = this.devolucao.venda_items.some((item) => {
        return item.devolucao > 0;
      });

      if (!existe_devolucao) {
        this.$toast.error(this.$tc("global.devolverPeloMenosUmItem"));
        return;
      }
      if (this.gerar_nota_credito && !this.selectedTimbrado) {
        this.$toast.error(this.$tc("global.SelecioneTimbrado") + "!");
        return;
      }
      let devolucao = {};
      let devolucao_itens = [];
      devolucao.venda_id = this.devolucao.id;
      devolucao.caixa_id = this.selectedCaixa.tipo_caixa == 'PDV' ? this.selectedCaixa.caixa_id : this.selectedCaixa.id;
      devolucao.total_devolucao = this.total_devolucao;
      devolucao.total_custo = this.total_custo;
      devolucao.total_desconto = Number(this.devolucao.desconto);
      devolucao.gerar_nota_credito = this.gerar_nota_credito;
      devolucao.forma_devolucao = this.forma_devolucao;
      if (this.gerar_nota_credito) {
        devolucao.timbrado_id = this.selectedTimbrado;
      }

      this.devolucao.venda_items.forEach((item) => {
        if (
          Number(item.devolucao) > 0 &&
          Number(item.devolucao) <=
            Number(item.qtde) - Number(item.qtde_devolucao)
        ) {
          devolucao_itens.push(item);
        }
      });
      devolucao.devolucao_itens = devolucao_itens;

      devolverVenda(devolucao)
        .then((response) => {
          if (this.gerar_nota_credito) {
            let fatura_id = response.data.fatura_id;
            this.generateFatura(fatura_id, true, false, false, "notaDeCredito");
            this.$emit("update:dialogDevolucao", false);
          } else {
            if (response.status === 200) {
              this.$emit("update:dialogDevolucao", false);
              this.$emit("fetch-devolucoes");
            }
          }
        })
        .catch(() => {});
    },

    devolucaoCompra() {
      devolverCompra()
        .then((response) => {
          console.log(response);
        })
        .catch(() => {});
    },

    async getVendaDevolucao() {
      this.loading = true;
      await fetchVendaDevolucao(this.item_id)
        .then((response) => {
          this.devolucao = response;
          this.devolucao.venda_items.map((item) => {
            item.total =
              (Number(item.qtde) - Number(item.qtde_devolucao)) *
              Number(item.preco);
            if (Number(item.qtde) - Number(item.qtde_devolucao) > 0) {
              item.isSelectable = true;
            } else {
              item.isSelectable = false;
            }
            item.devolucao = 0;
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    itemSelecionado(item) {
      if (item.value) {
        item.item.devolucao =
          Number(item.item.qtde) - Number(item.item.qtde_devolucao);
        item.item.isSelected = true;
        this.itemSelecionados++;
      } else {
        item.item.devolucao = 0;
        item.item.isSelected = false;
        this.itemSelecionados--;
      }
      this.totalDevolucao();
    },
    allItemsSelecionado(items) {
      this.itemSelecionados = 0;
      if (items.value) {
        this.devolucao.venda_items.map((item) => {
          item.devolucao = Number(item.qtde) - Number(item.qtde_devolucao);
          item.isSelected = true;
          if (item.devolucao > 0) {
            this.itemSelecionados++;
          }
        });
      } else {
        this.devolucao.venda_items.map((item) => {
          item.devolucao = 0;
          item.isSelected = false;
        });
      }
      this.totalDevolucao();
    },
    changeQnt(qnt, index) {
      this.devolucao.venda_items[index].devolucao = qnt;
      this.totalDevolucao();
      this.i++;
    },
    getCaixasFaturamento() {
      this.loading = true;

      fetchCaixasFaturamento(`?moeda_id=${this.devolucao.moeda_id}`)
        .then((response) => {
          this.caixas = response;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    if (this.tipo_devolucao === "vendas") {
      await this.getVendaDevolucao();
    }
    this.getCaixasFaturamento();
  },
};
</script>

<style scoped lang="scss">
.input-center ::v-deep input {
  text-align: center;
}
</style>
